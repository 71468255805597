.swal2-title {
  position: relative;
  max-width: 100%;
  margin: 0 0 0.4em;
  padding: 0;
  color: black;
  font-weight: 600;
  text-align: center;
  text-transform: none;
  word-wrap: break-word;
  font-size: 14px;
}

.swal2-content {
    z-index: 1;
    justify-content: center;
    margin: 0;
    padding: 0 1.6em;
    color: #545454;
    font-size: 16px;
    font-weight: normal;
    line-height: 1.4;
    text-align: center;
    word-wrap: break-word;
}

.swal2-styled.swal2-confirm {
    border: 0;
    border-radius: 0.25em;
    background: initial;
    background-color: rgb(196, 140, 4);
    color: #fff;
    font-size: 18px;
}

.swal2-icon.swal2-info {
  border-color: #e3bb5c;
  color: #e3bb5c;
}

.swal2-progress-steps .swal2-progress-step.swal2-active-progress-step {
  background:#e3bb5c;
}

